/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { OrgBasicInfo } from '../org-basic-info';
import { ResourceAccess } from '../resource-access';
import { ResourceType } from '../resource-type.enum';
import { LabelInfo } from '@app/app-launcher/app-launcher.component';

@Component({
  selector: 'app-app-icon-folder-structure',
  templateUrl: './app-icon-folder-structure.component.html',
  styleUrls: ['./app-icon-folder-structure.component.scss'],
})
export class AppIconFolderStructureComponent implements OnInit, OnChanges {
  @Input() public folderList: Array<OrgBasicInfo> = [];
  @Input() public resourceList: Array<ResourceAccess> = [];
  @Input() public labelsList: Array<LabelInfo> = [];
  @Input() public resourcesNotInLabels: Array<ResourceAccess> = [];
  @Input() public isSmallScreen = false;
  @Input() public resourceType = ResourceType.application;
  @Input() public parentOrg: string = '';
  @Input() public showStatusIcons = true;
  @Input() public searchFilterTextValue = '';
  @Input() public selectedTypes: { [key: string]: boolean } = {};
  @Output() public updateRequestIcons = new EventEmitter();
  public resourcesInLabel: Array<ResourceAccess> = [];
  public labelClicked: boolean = false; // Track if a label has been clicked
  public selectedLabel: string;

  public filteredResourcesInLabel: ResourceAccess[] = [];
  public filteredResourcesNotInLabels: ResourceAccess[] = [];

  constructor() {}

  public ngOnInit(): void {
    this.updateResourcesNotInLabels();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.resourceList) {
      this.updateResourcesNotInLabels();
    }
    if (changes.searchFilterTextValue) {
      this.applyAllResourceFilters();
    }
  }

  public onSubOrgClick(): void {
    this.updateResourcesNotInLabels();
  }

  private updateResourcesNotInLabels(): void {
    this.selectedLabel = undefined;
    this.labelClicked = false;
    this.resourcesInLabel = [];
    this.filteredResourcesInLabel = [];
    this.filteredResourcesNotInLabels = [...this.resourcesNotInLabels];
    this.applyAllResourceFilters();
  }

  public onLabelClickEvent(label: LabelInfo): void {
    this.resourcesInLabel = this.resourceList.filter((resource) => label.objects.some((obj) => obj.object_id === resource.id));
    this.filteredResourcesInLabel = [...this.resourcesInLabel];
    this.labelClicked = true;
    this.selectedLabel = label.name;
    this.applyAllResourceFilters();
  }

  public goBack(): void {
    this.updateResourcesNotInLabels();
  }

  public updateRequestIconsEvent(): void {
    this.updateRequestIcons.emit();
  }

  public applyAllResourceFilters(): void {
    let filteredResources = [];
    if (this.labelClicked) {
      filteredResources = this.filterResourcesByNameOrDescription(this.resourcesInLabel);
      filteredResources = this.filterResourcesByType(filteredResources);
      this.filteredResourcesInLabel = [...filteredResources];
    } else {
      filteredResources = this.filterResourcesByNameOrDescription(this.resourcesNotInLabels);
      filteredResources = this.filterResourcesByType(filteredResources);
      this.filteredResourcesNotInLabels = [...filteredResources];
    }
  }

  private filterResourcesByNameOrDescription(resources: Array<ResourceAccess>): Array<ResourceAccess> {
    if (!this.searchFilterTextValue) {
      return resources;
    }

    return resources.filter((resource) => {
      const nameMatches = resource.name.toLowerCase().includes(this.searchFilterTextValue);
      const descriptionMatches = resource.description?.toLowerCase().includes(this.searchFilterTextValue);
      return nameMatches || descriptionMatches;
    });
  }

  private filterResourcesByType(resources: Array<ResourceAccess>): Array<ResourceAccess> {
    if (Object.values(this.selectedTypes).length === 0 || Object.values(this.selectedTypes).every((value) => !value)) {
      return [...resources];
    }
    return resources.filter((resource) => this.selectedTypes[resource.resource_type]);
  }
}
